.main-App {
  display: flex;
  width: 100%;
}
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");
.fontset {
  font-family: "Audiowide" !important;
  text-align: center;
}
body {
  font-family: "Readex Pro", sans-serif;
}
.App {
  width: 100%;
  padding: 3.7rem 0px 0px 280px;
  transition: all 0.4s;
}
.closeIconsidebar {
  display: none;
}
@media (max-width: 900px) {
  .App {
    width: 100%;
    padding: 3.7rem 0px 0px 0px;
  }
  .closeIconsidebar {
    display: block;
  }
}

.css-1dimb5e-singleValue {
  color: white !important;
}

.css-b62m3t-container > :last-child {
  background: #1e3240;
  color: white;
}
.css-1u9des2-indicatorSeparator {
  display: none !important;
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #0a2334;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 20%);
  border-radius: 10px;
}
.css-hqv4f7-MuiBadge-badge {
  top: 5px !important;
  right: 7px !important;
}
a {
  text-decoration: none;
  color: white;
}
.custom-indicator {
  background-color: red !important;
}
.sortableBox.css-b62m3t-container > :last-child {
  background: rgba(0, 0, 0, 0.6) !important;
  color: white;
  border: none !important;
  width: 185px;
}

input::placeholder {
  color: white;
}
.css-tvwd1o-control:hover {
  border: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}
.grid-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  border-radius: 10px;
  background: #6c7b85;
  cursor: pointer;
}
.grid-list {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  gap: 23px;
  color: white;
  border-radius: 10px;
  margin-right:5px;
  background: #6c7b85;
  margin-bottom: 5px;
}

/* Hide arrows for Firefox */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.totalList{
  display: flex;
  margin-left:auto;
  padding-right: 10px

}
@media (max-width: 2600px) {
  .grid-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 2200px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1800px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1400px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
@media (max-width: 700px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
.tippyset{
  background-color:rgba(0, 0, 0, 0.8) !important;
}
.rpt-hover>:nth-child(1){
  border-bottom:1px solid rgb(237 229 229);
}

.rpt-hover p{
  padding:10px !important;

}

.heroImageBox {
  background-image: url("../public/heroNHL.jpg");
}
.migrationPopupBox{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0A2334;
  border-radius: 10px;
  color: white;
  outline: none;
  overflow: auto;
}